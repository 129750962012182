<template>
  <div :class="`likes-card`" v-if="item && item.user">
    <div class="likes-card-content">
      <div class="likes-card-user">
        <figure class="picture" :style="`--picture:url(${getPicture(item)})`"></figure>
      </div>
      <div class="likes-card-message">
        <div>
          <b class="user">@{{ item.user.user }}</b>
        </div>
        <span v-html="getTimeLapse({ date: item.date })"></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["item"],
  methods: {
    getPicture: function(item) {
      return item?.user?.picture;
    },
  },
};
</script>

<style lang="scss" scoped>
$notch_width: 72px;
.likes-card {
  &-content {
    @include Flex(inherit, flex-start, center);
    gap: $mpadding;
    padding: $mpadding;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      border-bottom: solid 2px $alto;
      width: $notch_width;
      left: calc(50% - #{$notch_width/2});
    }
  }
  &-user {
    .picture {
      @include Avatar($mpadding * 3);
    }
  }
  &-message {
    word-break: break-word;
    .user {
      margin: 0 $mpadding/2 0 0;
    }
  }
}
</style>
